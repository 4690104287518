import jwtDecode from 'jwt-decode';
import client from './api-client';
import { queryClient } from './query-client';

const localStorageKey = '__cove_leads__';

function setToken(token) {
  window.localStorage.setItem(`${localStorageKey}/token`, token);
}

function getToken() {
  const token = window.localStorage.getItem(`${localStorageKey}/token`);
  try {
    if (token && (jwtDecode(token)?.exp || 0) * 1000 < Date.now()) {
      window.localStorage.clear();
      return;
    }
  } catch (error) {
    console.error(error);
  }
  return token;
}

function getStrapiToken(cognitoTokens) {
  const isPublicLeads = true;

  console.log({
    window,
    'document.referrer': document.referrer,
    cognitoTokens,
  });
  if (
    window &&
    `${document.referrer}`.match(
      '^https://deploy-preview-([0-9])+--cove-leads.netlify.app.*'
    )
  ) {
    window.location = `${document.referrer}auth/cognito/callback?${cognitoTokens}`;
    return;
  }

  return client(`auth/cognito/callback?${new URLSearchParams(cognitoTokens)}`, {
    isPublicLeads,
  }).then(res => {
    queryClient.setQueryData('user', res.user);
    setToken(res.jwt);
  });
}

function logout() {
  window.localStorage.clear();
  window.location.href = `${process.env.REACT_APP_COVE_LEADS_API_URL}/logout/cognito`;
  if (typeof window !== 'undefined') {
    window?.SessionStack?.identify?.(false);
  }
  return Promise.resolve();
}

function getUser() {
  const isLeads = true;
  return client(`users/me`, {
    isLeads,
  }).then(res => res);
}

export { getToken, logout, getStrapiToken, getUser, setToken };
