import { createContext, useContext, useState } from 'react';
import * as clientClient from '../utils/client-client';
import { useUser } from '../hooks/useUser';
import FullPageSpinner from '../components/loader';

const ClientContext = createContext();

const ClientProvider = props => {
  const { data: user, status } = useUser();
  const [currClientId, setCurrClientId] = useState(null);
  const [currentClient, setCurrentClient] = useState(
    clientClient.clientInfo() || false
  );

  if (status !== 'success') {
    return <FullPageSpinner />;
  }

  return (
    <ClientContext.Provider
      value={{
        getClients: clientClient.getClients({ userId: user?.id }),
        getClient: async id => await clientClient.getClient(id),
        getCurrentClient: () => currClientId,
        setCurrentClient: id => setCurrClientId(id),
        clientInfo: () => currentClient,
        saveClientToLocalStorage: (values, reload) => {
          setCurrClientId(values.id);
          setCurrentClient(values);
          return clientClient.saveClientToLocalStorage(values, reload);
        },
        clearClientFromLocalStorage: reload => {
          setCurrentClient(false);
          return clientClient.clearClientFromLocalStorage(reload);
        },
      }}
      {...props}
    />
  );
};

const useClient = () => {
  const context = useContext(ClientContext);
  if (context === undefined) {
    throw new Error(`useClient must be used within a ClientProvider`);
  }
  return context;
};

const withClient = Component => {
  return function WrappedComponent(props) {
    return <Component {...props} auth={useClient()} />;
  };
};

export { ClientProvider, useClient, withClient };
