import client from './api-client';

const createClient = values => {
  const isLeads = true;
  return client(`clients`, {
    isLeads,
    body: {
      ...values,
      phone: values.phone ? values.phone.split(/\D*/).join('') : null,
    },
  })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
};

const saveClientToLocalStorage = values => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('currentClient', JSON.stringify(values));
  }
};

const clientInfo = () => {
  if (typeof window !== 'undefined') {
    const currentClient = window.localStorage.getItem('currentClient');
    if (currentClient) {
      try {
        return JSON.parse(currentClient);
      } catch (error) {
        console.error(error);
      }
    }
    return false;
  }
};

const clearClientFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem('currentClient');
  }
};

const getClients = async ({ userId }) => {
  const isLeads = true;
  const query = new URLSearchParams({
    _sort: 'id:DESC',
    _limit: -1,
    '_where[user]': userId,
  });
  const res = await client(`clients/?${query.toString()}`, {
    isLeads,
  });
  return res;
};

const getClient = async id => {
  if (typeof id === 'undefined') return { data: 'Please provide client id' };
  const isLeads = true;
  const res = await client(`clients/${id}`, {
    isLeads,
  });
  return res;
};

export {
  createClient,
  saveClientToLocalStorage,
  clientInfo,
  clearClientFromLocalStorage,
  getClient,
  getClients,
};
