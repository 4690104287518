import React from 'react';
import { Link } from '@reach/router';
import { Button as MuiButton, Link as MuiLink } from '@mui/material';

export const CRAButton = React.forwardRef(
  ({ to, href, children, ...props }, ref) => {
    const link = to || href;
    if (link?.[0] === '/') {
      return (
        <Link
          to={`${link}${link.slice(-1) !== '/' ? '/' : ''}`}
          {...{ ...props, children, ref }}
        />
      );
    }
    if (link?.slice(0, 3) === 'tel:')
      return (
        <a href={link} {...props} ref={ref}>
          {children}
        </a>
      );
    return (
      <MuiButton {...{ LinkComponent: 'a', ...props, to, href, ref }}>
        {children}
      </MuiButton>
    );
  }
);

CRAButton.displayName = 'CRAButton';

export const CRALink = React.forwardRef(
  ({ to, href, children, ...props }, ref) => {
    const link = to || href;
    if (link?.[0] === '/') {
      return (
        <Link
          to={`${link}${link.slice(-1) !== '/' ? '/' : ''}`}
          {...{ ...props, children, ref }}
        />
      );
    }
    if (link?.slice(0, 3) === 'tel:')
      return (
        <a href={link} {...props} ref={ref}>
          {children}
        </a>
      );
    return (
      <MuiLink {...{ component: 'a', ...props, to, href, ref }}>
        {children}
      </MuiLink>
    );
  }
);

CRALink.displayName = 'CRALink';
