import { lazy, Suspense, useEffect } from 'react';
import { Debug, Toaster } from '@shared/react-ui-core';
import { useAuth } from './context/auth';
import { FullPageSpinner } from './components/loader';
import AppProviders from './context';
import './output.css';

const loadAuthenticatedApp = () => import('./authenticated-app.js');
const AuthenticatedApp = lazy(loadAuthenticatedApp);
const UnauthenticatedApp = lazy(() => import('./unauthenticated-app.js'));

const PageWrapper = props => {
  try {
    return <div {...props}>{props.children}</div>;
  } catch (error) {
    console.log('CAUGHT RED HANDED!', error);
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      throw error;
    }
  }
};

const Authenticated = () => {
  const { token } = useAuth();
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <Suspense fallback={<FullPageSpinner />}>
      {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

function App() {
  return (
    <PageWrapper>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      <AppProviders>
        <Authenticated />
      </AppProviders>
      <Toaster />
      <Debug />
    </PageWrapper>
  );
}

export default App;
