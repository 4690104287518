import { createContext, useContext, useState } from 'react';

const ToastContext = createContext();

function ToastProvider(props) {
  const initialValue = { message: '', visible: false };
  const [toast, setToast] = useState(initialValue);

  const setNewToast = (value, timeout = 3000) => {
    setToast(
      { message: value, visible: true },
      setTimeout(() => setToast({ ...initialValue, message: value }), timeout)
    );
  };

  return (
    <ToastContext.Provider
      value={{ toast, setToast: setNewToast }}
      {...props}
    />
  );
}

function useToast() {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
}

export { ToastProvider, useToast };
