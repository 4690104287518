/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const Dots = ({ loading = true, success = false, error = false, ...props }) => {
  const firstDot = useRef();
  const secondDot = useRef();
  const thirdDot = useRef();

  const dots = css`
    display: ${loading || success || error ? 'grid' : 'none'};
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    position: relative;
    .dot {
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      background: #e4ded6;
      border-radius: 50%;
      transition: all 0.25s ease;
    }
    .first {
      right: 15px;
    }
    .third {
      right: -15px;
    }
  `;

  useEffect(() => {
    const animateUpDown = (e, i) => {
      if (!e?.style) return;
      e.style.left = `${-15 + 15 * i}px`;
      e.style.background = `#E4DED6`;
      let direction = 'up';
      if (!error) {
        return setInterval(() => {
          if (direction === 'up') {
            e.style.transform = 'translateY(5px)';
            direction = 'down';
          } else {
            e.style.transform = 'translateY(-5px)';
            direction = 'up';
          }
        }, 400);
      }
    };

    const animateComplete = e => {
      if (!e.style) return;
      e.style.left = '15px';
      e.style.transform = 'translateY(0)';
      e.style.background = '#00CDB8';
    };

    const animateError = e => {
      if (!e.style) return;
      e.style.left = '15px';
      e.style.transform = 'translateY(0)';
      e.style.background = '#E6312D';
    };

    let firstInterval;
    let secondInterval;
    let thirdInterval;

    if (loading) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      firstInterval = animateUpDown(firstDot.current, 0);
      setTimeout(() => {
        secondInterval = animateUpDown(secondDot.current, 1);
      }, 200);
      setTimeout(() => {
        thirdInterval = animateUpDown(thirdDot.current, 2);
      }, 400);
    }
    if (success) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      animateComplete(firstDot.current);
      animateComplete(secondDot.current);
      animateComplete(thirdDot.current);
    }
    if (error) {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
      animateError(firstDot.current);
      animateError(secondDot.current);
      animateError(thirdDot.current);
    }

    return () => {
      clearInterval(firstInterval);
      clearInterval(secondInterval);
      clearInterval(thirdInterval);
    };
  }, [loading, success, error]);

  return (
    <div css={dots} {...props}>
      <span className="dot first" ref={firstDot} />
      <span className="dot second" ref={secondDot} />
      <span className="dot third" ref={thirdDot} />
    </div>
  );
};

Dots.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
};

Dots.defaultProps = {
  loading: true,
  success: false,
  error: false,
};

export default Dots;
