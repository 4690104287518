import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './reset.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

try {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: 'https://6a6fc68031c340ca9091a51827fbcfa5@o282499.ingest.sentry.io/6489153',
      integrations: [new BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
} catch (error) {
  console.error('Sentry.init Error', error);
}

try {
  if (typeof window !== 'undefined') {
    window?.SessionStack?.getSessionId?.(function (sessionId) {
      if (!sessionId) {
        // No session is being recorded.
      } else {
        // sessionId -> the ID of the currently recorded session.
        console.log('sessionId', sessionId);
        Sentry.configureScope(scope => {
          scope.addEventProcessor(async event => {
            event.contexts = {
              sessionstack: {
                session_id: sessionId,
                timestamp: new Date().getTime(),
              },
            };

            return event;
          });
        });
      }
    });
  }
} catch (error) {
  console.error('SessionStack.getSessionId Error', error);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
