import isUndefined from 'lodash/isUndefined';

const localStorageKey = '__cove_leads__/token';

async function handleErrors(response) {
  if (response.status === 204) {
    return {};
  }
  if (!response.ok && response.status === 404) {
    const error = new Error('Not Found');
    error.response = response;
    throw error;
  }
  const data = await response.json();
  if (
    !data.id &&
    ((!response.ok && response.status >= 400) ||
      (!isUndefined(data.successful) && !data.successful))
  ) {
    const error = new Error(data?.warning?.message || data.message);
    error.response = response;
    error.data = data;
    throw error;
  }
  return data;
}

function client(
  endpoint,
  { body, isLeads = false, isPublicLeads = false, ...customConfig } = {}
) {
  const token = window.localStorage.getItem(localStorageKey);
  const headers = {
    'content-type': 'application/json',
  };
  if (token && !isPublicLeads) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(
      `${
        isLeads || isPublicLeads
          ? process.env.REACT_APP_COVE_LEADS_API_URL
          : process.env.REACT_APP_COVE_API_V4_URL
      }/${endpoint}`,
      config
    )
    .then(handleErrors);
}

function clientCoveStage(endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(localStorageKey);
  const headers = {
    'content-type': 'application/json',
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window
    .fetch(`${process.env.REACT_APP_COVESTAGE_API_V4_URL}/${endpoint}`, config)
    .then(handleErrors);
}

export { client as default, clientCoveStage };
