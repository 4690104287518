import { createContext, useContext, useState } from 'react';

const BreadcrumbsContext = createContext();

function BreadcrumbsProvider(props) {
  const [crumbs, setCrumbs] = useState([]);

  return (
    <BreadcrumbsContext.Provider
      value={{
        crumbs,
        setCrumbs,
        insertBreadcrumb: (position, breadcrumb) =>
          setCrumbs(curr => {
            curr.splice(position, 0, breadcrumb);
            return curr;
          }),
      }}
      {...props}
    />
  );
}

function useBreadcrumbs() {
  const context = useContext(BreadcrumbsContext);
  if (context === undefined) {
    throw new Error(
      `useBreadcrumbs must be used within a Breadcrumbs provider`
    );
  }
  return context;
}

function withBreadcrumbs(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} breadcrumbs={useBreadcrumbs()} />;
  };
}

export { BreadcrumbsProvider, useBreadcrumbs, withBreadcrumbs };
