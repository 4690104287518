import { createContext, useContext, useState } from 'react';
import * as authClient from '../utils/auth-client';

const AuthContext = createContext();

function AuthProvider(props) {
  const [token, setToken] = useState(authClient.getToken());
  const error = { message: '' };
  const logout = () => authClient.logout();
  return (
    <AuthContext.Provider
      value={{
        token,
        logout,
        error,
        checkToken: () => setToken(authClient.getToken()),
      }}
      {...props}
    />
  );
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function withAuth(Component) {
  return function WrappedComponent(props) {
    return <Component {...props} auth={useAuth()} />;
  };
}

export { AuthProvider, useAuth, withAuth };
