import { useQuery } from 'react-query';
import * as authClient from '../utils/auth-client';

const setUser = user => {
  if (typeof window !== 'undefined') {
    window?.SessionStack?.identify?.({
      userId: `${user.id}`, // Replace the USER-ID with the user id from your app
      email: `${user.email}`, // Not required
      displayName: `${user.firstName} ${user.lastName}`, // Not required

      // Add your own custom user variables here.
      role_str: `${user?.role?.id || user?.role}`,
      office_str: `${user?.office?.id || user?.office}`,
    });
  }
};

const getActiveUser = async () => {
  try {
    const user = await authClient.getUser();
    setUser(user);
    return user;
  } catch (error) {
    if (error?.match?.(/blocked/i)) {
      return { blocked: true };
    }
    console.error({ msg: 'other errors', error });
  }
};

function useUser() {
  return useQuery(['user'], () => getActiveUser(), {
    refetchOnWindowFocus: false,
  });
}

export { useUser };
