/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Global, css, useTheme, ThemeProvider } from '@emotion/react';
import { CartProvider } from '@shared/cart-react';
import { GlobalThemeProvider } from '@shared/react-ui-core';
import { ProductsProvider } from '@shared/react-product';
import datoLoad from './datoLoad';
import { AuthProvider } from './auth';
import { BreadcrumbsProvider } from './breadcrumbs';
import { ClientProvider } from './client';
import { ToastProvider } from './toast';
import { QueryProvider } from './query-context';
import theme from '../theme';
import { CRAButton, CRALink } from '../components/cra/Link';

const Globals = () => {
  const { fonts } = useTheme();
  return (
    <Global
      styles={css`
        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }
        h1 {
          font-family: ${fonts.primary};
        }
        html,
        body {
          font-style: normal;
          padding: 0;
          margin: 0;
        }
      `}
    />
  );
};

const partnerEnv =
  (typeof window !== 'undefined' && process.env.NODE_ENV) || 'development';

const AppProviders = ({ children }) => {
  const apiEnv = process.env.NODE_ENV || 'development';
  const partnerId = apiEnv !== 'production' ? '39770523' : '6400414';
  const datoToken = process.env.DATO_TOKEN || '7c8a71c5a466ac293a9f9f29233e78';
  const preview = apiEnv !== 'production';

  return (
    <GlobalThemeProvider
      overwriteTheme={{
        components: {
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: CRAButton,
            },
          },
          MuiLink: {
            defaultProps: {
              component: CRALink,
            },
          },
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Globals />
        <AuthProvider>
          <BreadcrumbsProvider>
            <QueryProvider>
              <CartProvider
                apiV4Url={process.env.REACT_APP_COVE_API_V4_URL}
                datoData={datoLoad()}
                prefixLocalStorageKey="leads/"
              >
                <ProductsProvider
                  partnerId={partnerId}
                  datoToken={datoToken}
                  preview={preview}
                >
                  <ClientProvider>
                    <ToastProvider>{children}</ToastProvider>
                  </ClientProvider>
                </ProductsProvider>
              </CartProvider>
            </QueryProvider>
          </BreadcrumbsProvider>
        </AuthProvider>
      </ThemeProvider>
    </GlobalThemeProvider>
  );
};

AppProviders.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AppProviders.defaultProps = {
  children: null,
};

export default AppProviders;
